import { Button, Modal, Upload } from 'antd';
import React from 'react';
import { CloseIcon } from '../../Assets/Svgs';
import { UploadOutlined } from '@ant-design/icons';

interface Props {
  open: boolean;
  updateModalOpen: (value: boolean) => void;
}

const ImporDoctorstModal: React.FC<Props> = (props: Props) => {
  const handleOk = () => {
    props.updateModalOpen(false);
  };

  const handleCancel = () => {
    props.updateModalOpen(false);
  };
  return (
    <Modal
      open={props.open}
      closeIcon={
        <button onClick={handleCancel}>
          <CloseIcon />
        </button>
      }
      centered
      width={498}
      styles={{ content: { borderRadius: '24px' } }}
      title={
        <div className="text-neutral90 text-base font-semibold">
          Import Doctor Data
        </div>
      }
      footer={false}
    >
      <div className="flex flex-col items-center gap-y-6 mt-6">
        <div className="text-gray500">
          Please browse the file you want to upload
        </div>
        <div className="w-full">
          <div className="text-gray400">Upload file</div>
          <Upload className="flex w-full">
            <Button className="upload-button w-full text-gray400">
              Browse Files
              <UploadOutlined className="upload-icon" />
            </Button>
          </Upload>
        </div>
        <Button
          size="large"
          type="primary"
          className="w-full rounded-[12px] text-white bg-elmokhtabarPrimary h-14"
          htmlType="submit"
          onClick={handleOk}
        >
          Upload
        </Button>
        <Button
          className="text-[#848484] hover:cursor-pointer bg-inherit border-none shadow-none"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default ImporDoctorstModal;
