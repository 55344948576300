import * as React from 'react';
import { SVGProps } from 'react';
const ImportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#C01E2E"
      fillRule="evenodd"
      d="M8 14.4A6.4 6.4 0 1 0 8 1.6a6.4 6.4 0 0 0 0 12.8Zm2.966-6.966-2.4-2.4a.8.8 0 0 0-1.132 0l-2.4 2.4a.8.8 0 1 0 1.132 1.132L7.2 7.53V10.4a.8.8 0 0 0 1.6 0V7.531l1.034 1.035a.8.8 0 0 0 1.132-1.132Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ImportIcon;
