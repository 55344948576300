import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import {
  CloseIcon,
  ShowHideIcon,
  TrashIcon,
  UploadLogo,
} from '../../Assets/Svgs';
import {
  deleteDoctorImage,
  updateDoctorDetails,
} from '../../Actions/ConfigActions';

interface Props {
  open: boolean;
  updateModalOpen: (value: boolean) => void;
  updateImageModalOpen: (value: boolean) => void;
  id?: number;
  setImageSrc: (value: string) => void;
  editedFormData: {
    name: string | undefined;
    phone_number: string | undefined;
    email: string;
    imageSrc: string;
  };
  setEditedFormData: (value: any) => void;
  initialImageSrc: string | undefined;
  setInitialImageSrc: (value: string) => void;
  imageId?: any;
}

const DoctorInfoModal: React.FC<Props> = (props: Props) => {
  const [imageUrl, setImageUrl] = useState<string>('');
  const [errorsMessages, setErrorsMessages] = useState({
    name: '',
    email: '',
    phone_number: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleCancel = () => {
    props.updateModalOpen(false);
  };
  const handleOpenImageModal = () => {
    props.updateImageModalOpen(true);
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;

    props.setEditedFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,6}$/;
  const alphaRegex = /^[A-Za-z\s]+$/;
  const blockInvalidChar = (e: any) =>
    ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
  const handleUpdateDoctor = async () => {
    if (props.editedFormData.name) {
      if (alphaRegex.test(props.editedFormData.name)) {
        if (
          props.editedFormData.phone_number?.length === 11 &&
          props.editedFormData.phone_number.split('')[0] === '0' &&
          props.editedFormData.phone_number.split('')[1] === '1'
        ) {
          if (emailRegex.test(props.editedFormData.email)) {
            if (!props.editedFormData.imageSrc) {
              try {
                deleteDoctorImage({ id: props.imageId });
                setIsLoading(false);
                handleCancel();
              } catch (err) {
                setIsLoading(false);
                handleCancel();
              }
            }
            if (props.editedFormData.imageSrc !== props.initialImageSrc) {
              if (
                props.id &&
                props.editedFormData.name &&
                props.editedFormData.phone_number
              ) {
                setIsLoading(true);
                try {
                  await updateDoctorDetails({
                    id: props.id,
                    name: props.editedFormData.name,
                    email: props.editedFormData.email,
                    phone_number: props.editedFormData.phone_number,
                    file: props.editedFormData.imageSrc,
                  });
                  setErrorsMessages(() => ({
                    name: '',
                    email: '',
                    phone_number: '',
                  }));
                  setIsLoading(false);
                  handleCancel();
                } catch (err) {
                  setErrorsMessages((prevData: any) => ({
                    ...prevData,
                    phone_number: 'Phone Number is already used',
                  }));
                  setIsLoading(false);
                }
              }
            } else {
              if (
                props.id &&
                props.editedFormData.name &&
                props.editedFormData.phone_number
              ) {
                setIsLoading(true);

                try {
                  await updateDoctorDetails({
                    id: props.id,
                    name: props.editedFormData.name,
                    email: props.editedFormData.email,
                    phone_number: props.editedFormData.phone_number,
                  });
                  setErrorsMessages(() => ({
                    name: '',
                    email: '',
                    phone_number: '',
                  }));
                  setIsLoading(false);
                  handleCancel();
                } catch (err) {
                  setErrorsMessages((prevData: any) => ({
                    ...prevData,
                    phone_number: 'Phone Number is already used',
                  }));
                  setIsLoading(false);
                }
              }
            }
          } else {
            setErrorsMessages((prevData: any) => ({
              ...prevData,
              email: 'Email must be valid email',
            }));
          }
        } else {
          setErrorsMessages((prevData: any) => ({
            ...prevData,
            phone_number: 'Phone number must be valid number',
          }));
        }
      } else {
        setErrorsMessages((prevData: any) => ({
          ...prevData,
          name: 'Only alphabets are allowed',
        }));
      }
    } else {
      setErrorsMessages((prevData: any) => ({
        ...prevData,
        name: 'Name is required',
      }));
    }
  };

  return (
    <Modal
      open={props.open}
      closeIcon={
        <button onClick={handleCancel}>
          <CloseIcon />
        </button>
      }
      centered
      width={720}
      styles={{ content: { borderRadius: '24px' } }}
      title={
        <div className="text-neutral90 text-xl font-semibold">
          Edit Dr.{props.editedFormData.name && props.editedFormData.name} (
          {props.id && props.id})
        </div>
      }
      footer={false}
    >
      <div className="flex flex-col items-center gap-y-6 mt-6">
        <Form className="flex flex-col  w-full">
          <div className="flex flex-row gap-3 ">
            <Form.Item className="text-gray500  ">
              <h1 className="text-gray400 text-xs font-medium pb-1">Name:</h1>
              <Input
                className="bg-gray100 text-Black font-medium border-0"
                type="text"
                size="large"
                name="name"
                defaultValue={props.editedFormData.name}
                placeholder="Enter Name"
                styles={{ affixWrapper: { padding: '12px' } }}
                onChange={handleChange}
              />
              {errorsMessages.name && (
                <p className="text-red-500">{errorsMessages.name}</p>
              )}
            </Form.Item>
            <Form.Item className="text-gray500">
              <h1 className=" text-gray400 text-xs font-medium pb-1">
                Mobile number
              </h1>
              <Input
                name="phone_number"
                className="bg-gray100 text-Black font-medium border-0 [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield]"
                type="number"
                onKeyDown={blockInvalidChar}
                pattern="[0-9\s]{13,19}"
                size="large"
                defaultValue={props.editedFormData.phone_number}
                placeholder="Enter Phone Number"
                styles={{ affixWrapper: { padding: '12px' } }}
                onChange={handleChange}
              />
              {errorsMessages.phone_number && (
                <p className="text-red-500">{errorsMessages.phone_number}</p>
              )}
            </Form.Item>
          </div>
          <div className="flex flex-row gap-3">
            <Form.Item className="text-gray500 w-[49%] ">
              <h1 className=" text-gray400 text-xs font-medium pb-1">
                Email (Optional)
              </h1>
              <Input
                name="email"
                className="bg-gray100 text-Black font-medium border-0"
                type="email"
                size="large"
                defaultValue={props.editedFormData.email}
                placeholder="Enter Email"
                styles={{ affixWrapper: { padding: '12px' } }}
                onChange={handleChange}
              />
              {errorsMessages.email && (
                <p className="text-red-500">{errorsMessages.email}</p>
              )}
            </Form.Item>
            <Form.Item className="text-gray500 w-[49%]">
              <h1 className="text-gray400 text-xs font-medium pb-1 ">
                Upload file verification
              </h1>

              <div className="flex flex-row max-md:flex-col-reverse gap-3">
                <div className="flex flex-row justify-center items-center ps-4 bg-gray50 py-1 rounded-lg border-[1px] border-gray700">
                  <Button
                    className={`w-fit border-0 bg-gray100 px-0 ${props.editedFormData.imageSrc || props.initialImageSrc ? '' : 'cursor-not-allowed opacity-50'} `}
                    onClick={handleOpenImageModal}
                    disabled={
                      !props.initialImageSrc && !props.editedFormData.imageSrc
                    }
                  >
                    <ShowHideIcon />
                  </Button>
                  <Input
                    name="imageSrc"
                    type="text"
                    placeholder="Upload Image"
                    className="w-[80%] border-0 bg-gray-100 text-neutral200 font-normal px-4"
                    value={
                      imageUrl
                        ? imageUrl
                        : props.initialImageSrc
                          ? props.initialImageSrc
                          : ''
                    }
                  />
                  <Button
                    className=" bg-gray100 border-0 ps-0 pe-4"
                    onClick={() => {
                      props.setEditedFormData((prevData: any) => ({
                        ...prevData,
                        imageSrc: null,
                      }));
                      setImageUrl('');
                      props.setImageSrc('');
                      props.setInitialImageSrc('');
                    }}
                  >
                    <TrashIcon />
                  </Button>
                </div>
                <div className="flex justify-center items-center w-[20%] max-md:h-[5vh] bg-[#CACACA] rounded-lg relative">
                  <Input
                    name="imageSrc"
                    type="file"
                    className=" w-full h-full bg-gray-200 opacity-0 cursor-pointer hover:file:cursor-pointer   absolute"
                    onChange={(e) => {
                      if (e.target.files) {
                        props.setEditedFormData({
                          ...props.editedFormData,
                          imageSrc: e.target.files[0],
                        });

                        props.setImageSrc(
                          URL.createObjectURL(e.target.files[0])
                        );
                        setImageUrl(e.target.files[0].name);
                      }
                    }}
                  />
                  <UploadLogo />
                </div>
              </div>
            </Form.Item>
          </div>
          <Button
            size="large"
            type="primary"
            className="w-full rounded-[12px] text-white bg-[#CACACA] h-14"
            htmlType="submit"
            onClick={handleUpdateDoctor}
            loading={isLoading}
          >
            Save
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

export default DoctorInfoModal;
