import { Text } from '../../text';

type Props = {
  message?: string;
};

export const NotificationMessage = ({ message }: Props) => {
  if (!message) return <Text>Loading...</Text>;

  return (
    <div>
      <Text
        style={{
          textAlign: 'right',
        }}
        className="text-neutral90 font-normal "
      >
        {message || 'Unassigned'}
      </Text>
    </div>
  );
};
