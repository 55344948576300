import React from 'react';
import { ExtractIcon, ImportIcon, StarIcon } from '../../Assets/Svgs';
import { Loading3QuartersOutlined } from '@ant-design/icons';

interface BulkPointsManagementProps {
  title: string;
  showImportButton?: boolean;
  showExtractButton?: boolean;
  onImportClick: (value: boolean) => void;
  onExtractClick?: () => void;
  exportLoading?: boolean;
}

const BulkPointsManagement: React.FC<BulkPointsManagementProps> = ({
  title,
  showImportButton = true,
  showExtractButton = true,
  onImportClick,
  onExtractClick,
  exportLoading,
}) => {
  return (
    <div className="flex items-center gap-x-2">
      <div className="flex items-center text-elborgPrimary text-base font-semibold gap-x-2">
        <div>{title}</div>
        <StarIcon />
      </div>
      {showImportButton && (
        <button
          onClick={() => onImportClick(true)}
          className="flex items-center gap-x-2 border-primary text-primary px-4 py-2 border rounded-[4px] shadow-sm cursor-pointer hover:bg-primary hover:text-white transition"
        >
          <div className="font-semibold">Import</div>
          <ImportIcon />
        </button>
      )}
      {showExtractButton && (
        <button
          onClick={onExtractClick}
          className="flex items-center gap-x-2 bg-primary text-white px-4 py-2 border rounded-[4px] shadow-sm cursor-pointer hover:bg-white hover:text-primary transition"
        >
          {exportLoading ? (
            <div>
              <Loading3QuartersOutlined spin />
            </div>
          ) : (
            <>
              <div className="font-semibold">Extract</div>
              <ExtractIcon />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default BulkPointsManagement;
