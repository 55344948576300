import * as React from 'react';
import { SVGProps } from 'react';
const EditIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.8685 2.86853C11.4934 2.24369 12.5064 2.24369 13.1313 2.86853C13.7561 3.49337 13.7561 4.50643 13.1313 5.13127L12.497 5.76559L10.2342 3.50285L10.8685 2.86853Z"
        fill="#F09B29"
      />
      <path
        d="M9.10285 4.63422L2.3999 11.3372V13.5999H4.66264L11.3656 6.89696L9.10285 4.63422Z"
        fill="#F09B29"
      />
    </svg>
  );
};
export default EditIcon;
