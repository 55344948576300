import * as React from 'react';
import { SVGProps } from 'react';
const AddMemberIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ? props.fill : '#CACACA'}
      d="M9.6 10.8a3.6 3.6 0 1 0 0-7.2 3.6 3.6 0 0 0 0 7.2ZM9.6 13.2a7.2 7.2 0 0 1 7.2 7.2H2.4a7.2 7.2 0 0 1 7.2-7.2ZM19.2 8.4a1.2 1.2 0 0 0-2.4 0v1.2h-1.2a1.2 1.2 0 0 0 0 2.4h1.2v1.2a1.2 1.2 0 0 0 2.4 0V12h1.2a1.2 1.2 0 0 0 0-2.4h-1.2V8.4Z"
    />
  </svg>
);
export default AddMemberIcon;
