import React from 'react';
import {
  LoginPageProps,
  LoginFormTypes,
  useActiveAuthProvider,
  useLogin,
  useTranslate,
} from '@refinedev/core';
import { ThemedTitleV2 } from '@refinedev/antd';
import { containerStyles, headStyles, layoutStyles } from './styles';
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  CardProps,
  LayoutProps,
  Divider,
  FormProps,
  theme,
} from 'antd';

type LoginProps = LoginPageProps<LayoutProps, CardProps, FormProps>;
/**
 * **refine** has a default login page form which is served on `/login` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#login} for more details.
 */
export const Login: React.FC<LoginProps> = ({
  providers,
  contentProps,
  wrapperProps,
  renderContent,
  formProps,
  title,
  hideForm,
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm<LoginFormTypes>();
  const translate = useTranslate();

  const authProvider = useActiveAuthProvider();
  const { mutate: login, isLoading } = useLogin<LoginFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });

  const PageTitle =
    title === false ? null : (
      <div
        style={{
          display: 'none',
          justifyContent: 'center',
          marginBottom: '32px',
          fontSize: '20px',
        }}
      >
        {title ?? <ThemedTitleV2 collapsed={false} />}
      </div>
    );

  const renderProviders = () => {
    if (providers && providers.length > 0) {
      return (
        <>
          {providers.map((provider) => {
            return (
              <Button
                key={provider.name}
                type="default"
                block
                icon={provider.icon}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  marginBottom: '8px',
                }}
                onClick={() =>
                  login({
                    providerName: provider.name,
                  })
                }
              >
                {provider.label}
              </Button>
            );
          })}
          {!hideForm && (
            <Divider>
              <Typography.Text
                style={{
                  color: token.colorTextLabel,
                }}
              >
                {translate('pages.login.divider', 'or')}
              </Typography.Text>
            </Divider>
          )}
        </>
      );
    }
    return null;
  };

  const CardContent = (
    <Card
      styles={{ header: headStyles, body: { padding: 0 } }}
      style={{
        ...containerStyles,
        backgroundColor: token.colorBgElevated,
      }}
      className="w-[100%] "
      {...(contentProps ?? {})}
    >
      {renderProviders()}
      {!hideForm && (
        <div className="flex flex-col w-[100%]  p-8 shadow-lg   xs:h-auto ">
          <div className="flex flex-col pb-4">
            <h1 className="text-[22px] font-semibold text-center tracking-wide ">
              Welcome back!
            </h1>
            <h1 className="text-[20px] font-medium">
              Please enter your details.
            </h1>
          </div>
          <Form<LoginFormTypes>
            layout="vertical"
            form={form}
            onFinish={(values) => login(values)}
            requiredMark={false}
            initialValues={{
              remember: false,
            }}
            className="w-full"
            {...formProps}
          >
            <Form.Item
              name="email"
              label={<div className="block text-xs text-gray400">Email</div>}
              rules={[
                {
                  required: true,
                  message: translate(
                    'pages.login.errors.requiredEmail',
                    'Email is required'
                  ),
                },
                {
                  type: 'email',
                  message: translate(
                    'pages.login.errors.validEmail',
                    'Invalid email address'
                  ),
                },
              ]}
            >
              <Input
                size="large"
                placeholder={translate('pages.login.fields.email', 'Email')}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={<div className="block text-xs text-gray400">Password</div>}
              rules={[
                {
                  required: true,
                  message: translate(
                    'pages.login.errors.requiredPassword',
                    'Password is required'
                  ),
                },
              ]}
            >
              <Input
                type="password"
                autoComplete="current-password"
                placeholder="●●●●●●●●"
                size="large"
              />
            </Form.Item>

            {!hideForm && (
              <Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isLoading}
                  block
                >
                  {translate('pages.login.signin', 'Login')}
                </Button>
              </Form.Item>
            )}
          </Form>
        </div>
      )}
    </Card>
  );

  return (
    <Layout style={layoutStyles} {...(wrapperProps ?? {})}>
      <Row
        justify="center"
        align={hideForm ? 'top' : 'middle'}
        style={{
          padding: '16px 0',
          minHeight: '100dvh',
          paddingTop: hideForm ? '15dvh' : '16px',
        }}
        className="bg-primary "
      >
        <Col className="w-[50%] min-w-[300px]">
          {renderContent ? (
            renderContent(CardContent, PageTitle)
          ) : (
            <>
              {PageTitle}
              {CardContent}
            </>
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default Login;
