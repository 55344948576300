import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { List, useTable } from '@refinedev/antd';
import { Table } from 'antd';
import './../styles.css';
import { ColHeaderLeft } from '@components/ColHeaderLeft';
export const ContactUsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List
      title={<div className="text-primaryRed lg:mb-9">Contact Us Requests</div>}
    >
      <Table
        {...tableProps}
        rowKey="id"
        bordered={false}
        pagination={{
          position: ['bottomCenter'],
          nextIcon: false,
          prevIcon: false,
        }}
        className="w-full border border-gray-300 rounded-md"
        style={{ padding: 0, paddingBlockStart: '0 !important' }}
      >
        <Table.Column
          className="table-header"
          dataIndex="name"
          title={<ColHeaderLeft title="Name" />}
          render={(value) => (
            <span className="text-gray500 font-semibold">{value}</span>
          )}
          width={200}
        />
        <Table.Column
          className="table-header"
          dataIndex="message"
          title={<ColHeaderLeft title="Message" />}
          render={(value) => (
            <span className="w-[100%] font-normal text-neutral90">{value}</span>
          )}
        />
        <Table.Column
          className="table-header"
          dataIndex="type"
          title={<ColHeaderLeft title="Type" />}
          render={(value) => (
            <span className="w-[100%] font-normal text-neutral90">{value}</span>
          )}
        />
        <Table.Column
          className="table-header"
          dataIndex="phone_number"
          title={<ColHeaderLeft title="Mobile number" />}
          render={(value) => (
            <span className="w-[100%] font-normal text-neutral90">{value}</span>
          )}
          width={200}
        />
      </Table>
    </List>
  );
};
