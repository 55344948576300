import * as React from 'react';
import { SVGProps } from 'react';
const DeleteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M6.417 19.25c-.504 0-.936-.18-1.295-.538a1.769 1.769 0 0 1-.539-1.295V5.5h-.916V3.667H8.25V2.75h5.5v.917h4.583V5.5h-.916v11.917c0 .504-.18.936-.538 1.295-.36.36-.79.539-1.296.538H6.417ZM15.583 5.5H6.417v11.917h9.166V5.5ZM8.25 15.583h1.833v-8.25H8.25v8.25Zm3.667 0h1.833v-8.25h-1.833v8.25Z"
    />
  </svg>
);
export default DeleteIcon;
