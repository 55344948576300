import { Button, Input, Modal } from 'antd';
import React from 'react';
import { CloseIcon } from '../../Assets/Svgs';

interface Props {
  open: boolean;
  updateModalOpen: (value: boolean) => void;
}

const AddMemberModal: React.FC<Props> = (props: Props) => {
  const handleOk = () => {
    props.updateModalOpen(false);
  };

  const handleCancel = () => {
    props.updateModalOpen(false);
  };
  return (
    <Modal
      open={props.open}
      closeIcon={
        <button onClick={handleCancel}>
          <CloseIcon />
        </button>
      }
      centered
      width={498}
      styles={{ content: { borderRadius: '24px' } }}
      footer={false}
    >
      <div className="flex flex-col items-center gap-y-6 mt-6">
        <div className="text-gray500">
          Add new member to shared wallet (Max: 4 members)
        </div>
        <Input
          pattern="[A-Za-z]+"
          placeholder={'Member Name'}
          className="bg-[#F5F5F5] border-[#C2C2C2] border rounded shadow-none py-3 px-4"
        />
        <Input
          pattern="[0-9]{11}"
          placeholder="Mobile Number"
          className="bg-[#F5F5F5] border-[#C2C2C2] border rounded shadow-none py-3 px-4"
        />
        <Button
          size="large"
          type="primary"
          className="w-full rounded-[12px] text-white bg-elmokhtabarPrimary h-14"
          htmlType="submit"
          onClick={handleOk}
        >
          Add
        </Button>
        <Button
          className="text-[#848484] hover:cursor-pointer bg-inherit border-none shadow-none"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default AddMemberModal;
