import * as React from 'react';
const BackIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <path
      fill="#757575"
      fillRule="evenodd"
      d="M7.766 13.366a.8.8 0 0 1-1.132 0l-4.8-4.8a.8.8 0 0 1 0-1.132l4.8-4.8a.8.8 0 1 1 1.132 1.132L4.33 7.2H13.6a.8.8 0 1 1 0 1.6H4.331l3.435 3.434a.8.8 0 0 1 0 1.132Z"
      clipRule="evenodd"
    />
  </svg>
);
export default BackIcon;
