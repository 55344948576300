import localforage from 'localforage';
interface IConfigsStore {
  // eslint-disable-next-line no-undef
  store?: LocalForage;
  logged_in?: boolean;
  rememberMe?: any;
  cookieExpiration?: any;
  subServicesDocuments?: any;
  roles?: any;
  adminRole?: any;
  resetPasswordEmail?: { email: string };
  resetPasswordToken?: string;
  resources?: any;
  device_id?: string;
}

class Configs implements IConfigsStore {
  // eslint-disable-next-line no-undef
  store: LocalForage;

  constructor() {
    this.store = localforage.createInstance({
      name: 'Configs',
      driver: localforage.LOCALSTORAGE,
    });
  }

  async getStore() {
    return (
      (await this.store.getItem<IConfigsStore>('store')) ||
      ({} as IConfigsStore)
    );
  }

  async get<T extends keyof IConfigsStore>(key: T) {
    const storeData = await this.getStore();

    return storeData[key];
  }

  async set<T>(key: keyof IConfigsStore, payload: T) {
    const storeData = await this.getStore();
    // @ts-ignore
    storeData[key] = payload;

    return this.store.setItem('store', storeData);
  }
}

export const ConfigsStore = new Configs();
