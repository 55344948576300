import { create } from 'zustand';

interface MenuState {
  isSelected: string;
  setIsSelected: (value: string) => void;
}

export const useMenuStore = create<MenuState>((set) => ({
  isSelected: '',
  setIsSelected: (value) => set({ isSelected: value }), // No need for the inner function
}));
