import React, { useState } from 'react';
import { List } from '@refinedev/antd';
import { Button, Table } from 'antd';
import { useLocation } from 'react-router-dom';
import { ColHeaderLeft } from '@components/ColHeaderLeft';
import { DoctorRes } from '../../types';
import { formatDate } from '../../Helpers/inedx';
import { AddMemberIcon, BackIcon, DeleteIcon } from '../../Assets/Svgs';
import { useNavigation } from '@refinedev/core';
import DeleteDoctortModal from '@components/DeleteModal';
import AddMemberModal from '@components/AddMemberModal';

const SharedWallet = () => {
  const [open, setModalOpen] = useState(false);
  const [AddMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const [memberID, setMemberID] = useState<number>(0);
  const { show } = useNavigation();
  const location = useLocation();
  const { data, isLoading } = location.state || {};
  const doctorData: DoctorRes = data;

  const navigateToShowPage = () => {
    const resource = 'account-cruds'; // Replace with your resource name
    const id = doctorData.id; // Replace with the ID of the record you want to show

    show(resource, id); // It navigates to the `/posts/show/1` page
  };
  const updateModalOpen = (value: boolean) => {
    setModalOpen(value);
  };
  const updateAddMemberModalOpen = (value: boolean) => {
    setAddMemberModalOpen(value);
  };
  return (
    <List
      title={
        <div className="flex gap-x-2 md:gap-x-8 md:mt-12 md:mb-9">
          <Button
            icon={<BackIcon />}
            onClick={navigateToShowPage}
            className="text-neutral70 font-semibold bg-transparent border-none shadow-none p-0"
          >
            Back
          </Button>
          <div className="flex gap-x-2 md:gap-x-6">
            <div className="text-neutral90 text-xl">Dr. {doctorData.name}</div>
            <div className="text-gray500 text-2xl">Shared Wallet</div>
          </div>
        </div>
      }
      headerButtons={
        <div className="flex md:mt-12 md:mb-9 gap-x-6">
          <div className="flex items-center text-gray600 text-xs font-light">
            Max: 4 members
          </div>
          <Button
            icon={
              <AddMemberIcon
                fill={doctorData.shared_wallet.length < 3 ? '#C01E2E' : ''}
              />
            }
            className={`${doctorData.shared_wallet.length < 3 ? '' : 'cursor-not-allowed'} ${doctorData.shared_wallet.length < 3 ? 'text-primary' : 'text-gray200'}  font-semibold bg-transparent border-none shadow-none`}
            onClick={() => updateAddMemberModalOpen(true)}
            disabled={doctorData.shared_wallet.length === 4}
          >
            Add Member
          </Button>
        </div>
      }
      // headerButtonProps={{}}
    >
      <Table
        dataSource={doctorData.shared_wallet}
        loading={isLoading}
        className="w-full border border-gray-300 rounded-lg"
        onHeaderRow={() => ({ className: 'table-header' })}
        rowKey="id"
        bordered={false}
        pagination={{
          position: ['bottomCenter'],
          nextIcon: false,
          prevIcon: false,
          pageSize: 9,
        }}
        style={{
          padding: 0,
          overflowX: 'scroll',
          paddingBlockStart: '0 !important',
        }}
      >
        <Table.Column
          className="table-header"
          dataIndex="name"
          title={<ColHeaderLeft title="Member Name" />}
          render={(value) => (
            <span className="text-neutral90 text-sm	 font-normal">{value}</span>
          )}
        />
        <Table.Column
          className="table-header"
          dataIndex="phone"
          title={<ColHeaderLeft title="Mobile number" />}
          render={(value) => (
            <span className="text-neutral90 text-sm	 font-normal">{value}</span>
          )}
        />
        <Table.Column
          className="table-header"
          dataIndex="created_at"
          title={<ColHeaderLeft title="Date Added" />}
          render={(value) => (
            <span className="text-neutral90 text-sm	 font-normal">
              {formatDate(value)}
            </span>
          )}
        />
        <Table.Column
          className="table-header"
          title={'Delete'}
          render={(record) => (
            <span className="flex justify-center  font-normal text-gray500">
              <DeleteIcon
                onClick={() => {
                  setMemberID(record.id);
                  setModalOpen(true);
                }}
              />
            </span>
          )}
        />
      </Table>
      <DeleteDoctortModal
        open={open}
        updateModalOpen={updateModalOpen}
        id={memberID}
        title="Delete Member?"
        header="Are you sure you want to delete this member?"
      />
      <AddMemberModal
        open={AddMemberModalOpen}
        updateModalOpen={updateAddMemberModalOpen}
      />
    </List>
  );
};

export default SharedWallet;
