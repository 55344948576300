import * as React from 'react';
import { SVGProps } from 'react';
import { useMenuStore } from '../../Store/IconsStore';
const NewsLetterIcon = (props: SVGProps<SVGSVGElement>) => {
  const { isSelected } = useMenuStore();
  //   fill={isSelected === '/contact-us' ? '#fff' : '#535353'}

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      {...props}
    >
      <path
        fill={isSelected === '/contact-us' ? '#fff' : '#535353'}
        d="M16.41 4.167H3.59c-1.09 0-1.923.842-1.923 1.944v7.778c0 1.102.833 1.944 1.923 1.944h12.82c1.09 0 1.923-.842 1.923-1.944V6.111c0-1.102-.833-1.944-1.923-1.944Zm.513 9.981-5-3.63L10 11.816l-1.987-1.296-4.936 3.63 4.038-4.214L2.18 6.046 10 10.324l7.756-4.213-4.871 3.89 4.038 4.147Z"
      />
    </svg>
  );
};
export default NewsLetterIcon;
