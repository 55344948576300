import { Datum, Params } from '../types';

export const customTitleHandler = ({ resource, action }: Params) => {
  let title = 'IDH Dashboard';
  if (resource && action) {
    title = `${resource.meta?.label} `;
  }

  return title;
};

export const transformData = (data: Datum[]) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return data?.map(({ monthYear, firstClass, excellence }) => {
    const [, month] = monthYear.split('-');
    const monthName = months[parseInt(month, 10) - 1];
    return { month: monthName, firstClass: firstClass, excellence: excellence };
  });
};

export const formatDate = (inputDate: string) => {
  const date = new Date(inputDate);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
};

export const formatNumberWithCommas = (number: number): string => {
  // Convert number to string and use regex to add commas
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
