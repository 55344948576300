import * as React from 'react';
import { SVGProps } from 'react';
const BellIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      stroke="#424242"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.5 14.667h4.167l-1.171-1.171A1.693 1.693 0 0 1 15 12.299V9.667a5.002 5.002 0 0 0-3.333-4.716v-.284a1.667 1.667 0 0 0-3.334 0v.284A5.002 5.002 0 0 0 5 9.667v2.632c0 .449-.178.88-.496 1.197l-1.17 1.17H7.5m5 0v.834a2.5 2.5 0 0 1-5 0v-.833m5 0h-5"
    />
  </svg>
);
export default BellIcon;
