import * as React from 'react';
import { SVGProps } from 'react';
import { useMenuStore } from '../../Store/IconsStore';
const DoctorIcon = (props: SVGProps<SVGSVGElement>) => {
  const { isSelected } = useMenuStore();
  //   fill={isSelected === '/account-cruds' ? '#fff' : '#535353'}

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill={isSelected === '/account-cruds' ? '#fff' : '#535353'}
      {...props}
    >
      <path
        fill={isSelected === '/account-cruds' ? '#fff' : '#535353'}
        d="M10 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm-3 1.725a5.573 5.573 0 0 0-4 5.347c0 .512.416.928.928.928h12.144a.928.928 0 0 0 .928-.928 5.573 5.573 0 0 0-4-5.347v1.588a2.002 2.002 0 0 1 1.5 1.937v1.25c0 .275-.225.5-.5.5h-.5a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5v-.75a.999.999 0 1 0-2 0V16c.275 0 .5.225.5.5s-.225.5-.5.5H11a.501.501 0 0 1-.5-.5v-1.25c0-.931.637-1.716 1.5-1.938v-1.784a5.76 5.76 0 0 0-.572-.028H8.572c-.194 0-.384.01-.572.028v2.044a1.751 1.751 0 1 1-2.25 1.678c0-.794.528-1.463 1.25-1.678v-1.847ZM7.5 16a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
      />
    </svg>
  );
};
export default DoctorIcon;
