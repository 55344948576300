import * as React from 'react';
import { SVGProps } from 'react';
const ResetIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#777"
      d="M11.224 10.5A5.427 5.427 0 0 0 12.5 7 5.494 5.494 0 0 0 3 3.233V1H2v4h4V4H3.666a4.489 4.489 0 1 1-1.05 4h-1.02A5.506 5.506 0 0 0 7 12.5c1.28 0 2.52-.451 3.5-1.276L14.293 15l.707-.707-3.776-3.793Z"
    />
  </svg>
);
export default ResetIcon;
