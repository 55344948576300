export const API_URL = (() => {
  return window.location.host.includes('localhost') ||
    window.location.host.includes('dev')
    ? 'https://loynova-loyalty.dev.wtmsrv.com/api'
    : window.location.host.includes('stagin')
      ? 'https://loynova-loyalty.staging.wtmsrv.com/api'
      : 'https://api.loyalty.loynova.com/api';
})();
export const API_KEY = 'TE9ZTk9WQUxPWUFMVFlBUElLRVlHVUFSRDIwMjI';
export const bundle_id = 'idh.admin.loynova.loyalty';
