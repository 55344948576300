import React, { useState } from 'react';
import { Menu, Dropdown, Switch } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css'; // Import Ant Design styles
import { useNavigation } from '@refinedev/core';
import { DoctorRes } from '../../types';
import DeleteDoctortModal from '@components/DeleteModal';
import { updateActiveUser } from '../../Actions/ConfigActions';

const ActionMenu = ({
  record,
  refetch,
}: {
  record: DoctorRes;
  refetch: () => void;
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const updateModalOpen = (value: boolean) => {
    setModalOpen(value);
  };
  const { show } = useNavigation();
  const handleDeactivateClick = () => {
    updateActiveUser({ id: record.id });
  };

  const handleShowClick = () => {
    show('account-cruds', record.id); // Navigate to the show page for the post with ID 1
  };
  const menu = (
    <Menu className="text-neutral90">
      <Menu.Item key="1">
        <div className="flex justify-between items-center text-neutral90">
          <span>{record.is_deleted ? 'Activate' : 'Deactivate'}</span>
          <Switch
            checked={record.is_deleted}
            onChange={handleDeactivateClick}
          />
        </div>
      </Menu.Item>
      <Menu.Item key="2">
        <span className="text-neutral90"> Points Management</span>
      </Menu.Item>
      <Menu.Item key="3" onClick={handleShowClick}>
        <span className="text-neutral90">Profile Info</span>
      </Menu.Item>
      <Menu.Item onClick={() => updateModalOpen(true)} key="4">
        <span className="text-red-500">Delete Doctor</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Dropdown overlay={menu} trigger={['click']}>
        <MoreOutlined size={22} style={{ color: '#A0A0A0' }} />
      </Dropdown>
      <DeleteDoctortModal
        id={record.id}
        updateModalOpen={updateModalOpen}
        open={modalOpen}
        refetch={refetch}
      />
    </div>
  );
};

export default ActionMenu;
