import React from 'react';
import {
  useNavigation,
  useRefineContext,
  useResource,
  useToPath,
  useRouterType,
  useGo,
} from '@refinedev/core';
import {
  EditButton,
  DeleteButton,
  RefreshButton,
  ListButton,
  Breadcrumb,
  PageHeader,
  ListButtonProps,
  EditButtonProps,
  DeleteButtonProps,
  RefreshButtonProps,
} from '@refinedev/antd';
import { Card, Space, Spin } from 'antd';
import type { ShowProps } from '@refinedev/antd';
import { LoadingOutlined } from '@ant-design/icons';

export const Show: React.FC<ShowProps> = ({
  title,
  canEdit,
  canDelete,
  isLoading = false,
  children,
  resource: resourceFromProps,
  recordItemId,
  dataProviderName,
  breadcrumb: breadcrumbFromProps,
  contentProps,
  headerProps,
  wrapperProps,
  headerButtons,
  footerButtons,
  footerButtonProps,
  headerButtonProps,
}) => {
  const { options: { breadcrumb: globalBreadcrumb } = {} } = useRefineContext();
  const routerType = useRouterType();
  const go = useGo();
  const { list: legacyGoList } = useNavigation();

  const {
    resource,
    id: idFromParams,
    identifier,
  } = useResource(resourceFromProps);

  const goListPath = useToPath({
    resource,
    action: 'list',
  });

  const id = recordItemId ?? idFromParams;

  const breadcrumb =
    typeof breadcrumbFromProps === 'undefined'
      ? globalBreadcrumb
      : breadcrumbFromProps;

  const hasList = resource?.list && !recordItemId;
  const isDeleteButtonVisible =
    canDelete ?? resource?.meta?.canDelete ?? resource?.canDelete;
  const isEditButtonVisible = canEdit ?? resource?.canEdit ?? !!resource?.edit;

  const listButtonProps: ListButtonProps | undefined = hasList
    ? {
        resource: routerType === 'legacy' ? resource?.route : identifier,
      }
    : undefined;
  const editButtonProps: EditButtonProps | undefined = isEditButtonVisible
    ? {
        ...(isLoading ? { disabled: true } : {}),
        type: 'primary',
        resource: routerType === 'legacy' ? resource?.route : identifier,
        recordItemId: id,
      }
    : undefined;
  const deleteButtonProps: DeleteButtonProps | undefined = isDeleteButtonVisible
    ? {
        ...(isLoading ? { disabled: true } : {}),
        resource: routerType === 'legacy' ? resource?.route : identifier,
        recordItemId: id,
        onSuccess: () => {
          if (routerType === 'legacy') {
            legacyGoList(resource?.route ?? resource?.name ?? '');
          } else {
            go({ to: goListPath });
          }
        },
        dataProviderName,
      }
    : undefined;
  const refreshButtonProps: RefreshButtonProps = {
    ...(isLoading ? { disabled: true } : {}),
    resource: routerType === 'legacy' ? resource?.route : identifier,
    recordItemId: id,
    dataProviderName,
  };

  const defaultHeaderButtons = (
    <>
      {hasList && <ListButton {...listButtonProps} />}
      {isEditButtonVisible && <EditButton {...editButtonProps} />}
      {isDeleteButtonVisible && <DeleteButton {...deleteButtonProps} />}
      <RefreshButton {...refreshButtonProps} />
    </>
  );

  return (
    <div {...(wrapperProps ?? {})} className="mt-5 lg:mt-12">
      <PageHeader
        ghost={false}
        title={
          <div className="showHeader">
            <div> {title ? title : <LoadingOutlined />}</div>
          </div>
        }
        // Needed to add extra buttons
        extra={
          <Space key="extra-buttons" wrap {...(headerButtonProps ?? {})}>
            {headerButtons
              ? typeof headerButtons === 'function'
                ? headerButtons({
                    defaultButtons: defaultHeaderButtons,
                    deleteButtonProps,
                    editButtonProps,
                    listButtonProps,
                    refreshButtonProps,
                  })
                : headerButtons
              : defaultHeaderButtons}
          </Space>
        }
        breadcrumb={
          typeof breadcrumb !== 'undefined' ? (
            <>{breadcrumb}</> ?? undefined
          ) : (
            <Breadcrumb />
          )
        }
        {...(headerProps ?? {})}
      >
        <Spin spinning={isLoading}>
          <Card
            bordered={false}
            style={{ border: 'none', boxShadow: 'none' }}
            styles={{ body: { padding: 0 } }}
            actions={
              footerButtons
                ? [
                    <Space key="footer-buttons" wrap {...footerButtonProps}>
                      {typeof footerButtons === 'function'
                        ? footerButtons({
                            defaultButtons: null,
                          })
                        : footerButtons}
                    </Space>,
                  ]
                : undefined
            }
            {...(contentProps ?? {})}
          >
            {children}
          </Card>
        </Spin>
      </PageHeader>
    </div>
  );
};
