import React, { useState } from 'react';
import { Modal, Button, Input, Radio, Alert } from 'antd';
import { CloseIcon } from '../../Assets/Svgs';
import { DoctorRes } from '../../types';
import { DoctorPointsManagement } from '../../Actions/ConfigActions';

interface Props {
  open: boolean;
  updateModalOpen: (value: boolean) => void;
  doctor: DoctorRes | undefined;
  accumulationFactor: number;
  setAccumulationFactor: (value: number) => void;
  redemptionRate: number | undefined;
  setRedemptionRate: (value: number | undefined) => void;
}

const DoctorPointsManagementModal: React.FC<Props> = ({
  open,
  updateModalOpen,
  doctor,
  accumulationFactor,
  setAccumulationFactor,
  redemptionRate,
  setRedemptionRate,
}) => {
  const [bonusPoints, setBonusPoints] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState('');
  const handleOk = async () => {
    if (
      redemptionRate !== undefined &&
      redemptionRate !== null &&
      bonusPoints >= 0 &&
      doctor?.id
    ) {
      try {
        await DoctorPointsManagement({
          ids: [doctor.id],
          factor_points: accumulationFactor,
          redemption_rate: redemptionRate,
          add_points: bonusPoints,
        });
        updateModalOpen(false);
      } catch (err) {
        setErrorMessage('Something went wrong kindly try again');
      }
    }
  };
  const handleCancel = () => {
    updateModalOpen(false);
  };

  const isSaveDisabled =
    redemptionRate === undefined || redemptionRate === null || bonusPoints <= 0;

  // Determine the bundle type based on the BundleID
  const bundleType =
    doctor?.systemConfigs.BundleID === 'idh.firstclass.loynova.loyalty'
      ? 'First Class'
      : 'Excellence';

  const primaryClass =
    bundleType === 'Excellence' ? 'text-elborgPrimary' : 'text-primary';

  const bgClass =
    bundleType === 'Excellence' ? 'bg-elborgPrimary' : 'bg-primary';

  const buttonClass =
    bundleType === 'Excellence' ? 'bg-elborgPrimary' : 'bg-primary';

  const radioClass =
    bundleType === 'Excellence' ? 'excellence-radio' : 'firstclass-radio';

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      centered
      width={600}
      closeIcon={
        <button onClick={handleCancel}>
          <CloseIcon />
        </button>
      }
      footer={null}
    >
      <div className={'text-xl font-semibold mb-6 flex justify-between'}>
        <span>
          Dr. {doctor?.name} ({doctor?.id}) - Points Management
        </span>
      </div>
      <div
        className={`flex justify-between mb-2 text-sm font-semibold ${primaryClass}`}
      >
        <span className="flex-1 text-left">{bundleType}</span>
        <span className="flex-1 text-center mr-1">Redemption Rate</span>
        <span className="flex-1 text-right mr-8">Add Bonus Points</span>
      </div>
      <div className="flex justify-between space-x-8 mb-6">
        <div className="flex-1 text-left">
          <span
            className={`${bgClass} text-white p-2 rounded-lg block text-center font-semibold text-lg`}
          >
            {doctor?.card_details.AllPoints ?? 0}
            <sub className="ml-1 text-xs">PTS</sub>
            <span className="block text-center font-semibold text-sm">
              Current Points
            </span>
          </span>
        </div>
        <div className="flex-1 text-left">
          <div className="relative">
            <Input
              type="number"
              value={redemptionRate}
              onChange={(e) =>
                setRedemptionRate(
                  e.target.value ? parseFloat(e.target.value) : undefined
                )
              }
              className="block px-2 py-3 rounded-lg"
              style={{ textAlign: 'left' }}
            />
            <span className="absolute right-3 top-1/2 text-gray-400 transform -translate-y-1/2">
              %
            </span>
          </div>
        </div>
        <div className="flex-1 text-left">
          <Input
            type="number"
            value={bonusPoints}
            onChange={(e) =>
              setBonusPoints(e.target.value ? parseFloat(e.target.value) : 0)
            }
            className="block px-2 py-3 rounded-lg"
          />
        </div>
      </div>
      <div className={`mb-4 font-semibold text-sm ${primaryClass}`}>
        Accumulation Factor
      </div>
      <div
        className={`grid grid-cols-4 gap-4 mb-6 text-sm font-normal ${radioClass}`}
      >
        {[
          { label: '@x0.25', value: 0.25 },
          { label: '@x0.5', value: 0.5 },
          { label: '@x0.75', value: 0.75 },
          { label: '@x1', value: 1 },
          { label: '@x1.25', value: 1.25 },
          { label: '@x1.5', value: 1.5 },
          { label: '@x1.75', value: 1.75 },
          { label: '@x2', value: 2 },
        ].map((factor) => (
          <Radio
            key={factor.value}
            checked={accumulationFactor === factor.value}
            onChange={() => {
              setAccumulationFactor(factor.value);
            }}
          >
            {factor.label}
          </Radio>
        ))}
      </div>
      {errorMessage && <Alert message={errorMessage} type="error" />}
      <Button
        type="primary"
        onClick={handleOk}
        disabled={isSaveDisabled}
        className={`w-full font-semibold text-base text-White ${buttonClass} ${!isSaveDisabled && bundleType === 'Excellence' ? 'hover:!bg-[#f5cd5f]' : ''} ${!isSaveDisabled && bundleType === 'First Class' ? 'hover:!bg-[#CC414B]' : ''}`}
      >
        Save
      </Button>
    </Modal>
  );
};

export default DoctorPointsManagementModal;
