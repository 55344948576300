import * as React from 'react';
import { SVGProps } from 'react';
const Logout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.833 6.667 14.167 10m0 0-3.334 3.333M14.167 10H2.5m4.167-3.333v-.834a2.5 2.5 0 0 1 2.5-2.5H15a2.5 2.5 0 0 1 2.5 2.5v8.334a2.5 2.5 0 0 1-2.5 2.5H9.167a2.5 2.5 0 0 1-2.5-2.5v-.834"
    />
  </svg>
);
export default Logout;
