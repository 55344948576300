export const speciality = [
  { value: 'All', label: 'All' },
  {
    value: 'Internal Medicine',
    label: 'Internal Medicine',
  },
  {
    value: 'Allergy & Immunology',
    label: 'Allergy & Immunology',
  },
  {
    value: 'Rheumatology & Immunology',
    label: 'Rheumatology & Immunology',
  },
  {
    value: 'Cardiology',
    label: 'Cardiology',
  },
  {
    value: 'Hepatology & Gastro-Enterology',
    label: 'Hepatology & Gastro-Enterology',
  },
  {
    value: 'Nephrology',
    label: 'Nephrology',
  },
  {
    value: 'Urology & Nephrology',
    label: 'Urology & Nephrology',
  },
  {
    value: 'Endocrinology',
    label: 'Endocrinology',
  },
  {
    value: 'Diabetes & Endocrinology',
    label: 'Diabetes & Endocrinology',
  },
  {
    value: 'Emergency Medicine',
    label: 'Emergency Medicine',
  },
  {
    value: 'Infectious Disease & Tropical',
    label: 'Infectious Disease & Tropical',
  },
  {
    value: 'Chest',
    label: 'Chest',
  },
  {
    value: 'Nutrition',
    label: 'Nutrition',
  },
  {
    value: 'Family Medicine',
    label: 'Family Medicine',
  },
  {
    value: 'Geriatric Medicine',
    label: 'Geriatric Medicine',
  },
  {
    value: 'General Surgery',
    label: 'General Surgery',
  },
  {
    value: 'Onco-Surgery',
    label: 'Onco-Surgery',
  },
  {
    value: 'Vascular Surgery',
    label: 'Vascular Surgery',
  },
  {
    value: 'Bariatric Surgery',
    label: 'Bariatric Surgery',
  },
  {
    value: 'Plastic Surgery',
    label: 'Plastic Surgery',
  },
  {
    value: 'Pediatric Surgery',
    label: 'Pediatric Surgery',
  },
  {
    value: 'Neuro-Surgery',
    label: 'Neuro-Surgery',
  },
  {
    value: 'Cardio-Thoracic Surgery',
    label: 'Cardio-Thoracic Surgery',
  },
  {
    value: 'Orthopedic Surgery',
    label: 'Orthopedic Surgery',
  },
  {
    value: 'Gastro-Intestinal Surgery',
    label: 'Gastro-Intestinal Surgery',
  },
  {
    value: 'Head & Neck Surgery',
    label: 'Head & Neck Surgery',
  },
  {
    value: 'Opthalmology',
    label: 'Opthalmology',
  },
  {
    value: 'Skin & Venerisal Disease',
    label: 'Skin & Venerisal Disease',
  },
  {
    value: 'Dermatology',
    label: 'Dermatology',
  },
  {
    value: 'Andrology',
    label: 'Andrology',
  },
  {
    value: 'Pediatric',
    label: 'Pediatric',
  },
  {
    value: 'Neonatology',
    label: 'Neonatology',
  },
  {
    value: 'Obstetrics & Gynacology',
    label: 'Obstetrics & Gynacology',
  },
  {
    value: 'Orthopedic',
    label: 'Orthopedic',
  },
  {
    value: 'Physotherapy',
    label: 'Physotherapy',
  },
  {
    value: 'Ear & Nose & Throat',
    label: 'Ear & Nose & Throat',
  },
];

export const brand = [
  {
    value: 'All',
    label: 'All',
  },
  {
    value: 'idh.firstclass.loynova.loyalty',
    label: 'First Class',
  },
  {
    value: 'idh.excellence.loynova.loyalty',
    label: 'Excellence',
  },
];
export const activation = [
  {
    value: 'false',
    label: 'Active',
  },
  {
    value: 'true',
    label: 'Inactive',
  },
];
//redeemed_transactions = Purchase
export const fieldsPurchase: { label: string; value: string }[] = [
  { label: 'Transaction ID', value: 'id' },
  { label: 'Amount', value: 'amount' },
  { label: 'Transaction Date', value: 'trx_date' },
  { label: 'Time', value: 'time' },
  { label: 'Merchant Name', value: 'merchant_name' },
];
//rewarding_transactions = Points
export const fieldsPoints: { label: string; value: string }[] = [
  { label: 'Transaction ID', value: 'id' },
  { label: 'Merchant Name', value: 'merchant_name' },
  { label: 'Amount', value: 'amount' },
  { label: 'Type', value: 'epoints_type' },
  { label: 'Points', value: 'points' },
  { label: 'Transaction Date', value: 'trx_date' },
  { label: 'Time', value: 'time' },
];
