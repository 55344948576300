import * as React from 'react';
import { SVGProps } from 'react';
const ShowHideIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        fill="#C01E2E"
      />
      <path
        d="M23.2049 11.745C22.3228 9.46324 20.7914 7.48996 18.8 6.06906C16.8086 4.64817 14.4445 3.84193 11.9999 3.75C9.55531 3.84193 7.19122 4.64817 5.19983 6.06906C3.20844 7.48996 1.67705 9.46324 0.794925 11.745C0.73535 11.9098 0.73535 12.0902 0.794925 12.255C1.67705 14.5368 3.20844 16.51 5.19983 17.9309C7.19122 19.3518 9.55531 20.1581 11.9999 20.25C14.4445 20.1581 16.8086 19.3518 18.8 17.9309C20.7914 16.51 22.3228 14.5368 23.2049 12.255C23.2645 12.0902 23.2645 11.9098 23.2049 11.745ZM11.9999 16.875C11.0357 16.875 10.0932 16.5891 9.29152 16.0534C8.48983 15.5177 7.86499 14.7564 7.49601 13.8656C7.12704 12.9748 7.03049 11.9946 7.2186 11.0489C7.4067 10.1033 7.871 9.23464 8.55278 8.55285C9.23456 7.87107 10.1032 7.40677 11.0489 7.21867C11.9945 7.03057 12.9747 7.12711 13.8655 7.49609C14.7563 7.86506 15.5177 8.48991 16.0533 9.2916C16.589 10.0933 16.8749 11.0358 16.8749 12C16.8729 13.2923 16.3587 14.5311 15.4449 15.445C14.5311 16.3588 13.2922 16.873 11.9999 16.875Z"
        fill="#C01E2E"
      />
    </svg>
  );
};
export default ShowHideIcon;
