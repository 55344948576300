import { ConfigsStore } from '../Store';
import { apiRequest } from '../rest-data-provider/utils/APIs';

export const CreateDeviceAction = async () => {
  const language = 'ar';
  const os = 'browser';

  const currentDevice = await ConfigsStore.get('device_id');
  if (currentDevice) return;
  try {
    const { data: deviceData } = await apiRequest<{ id: string }>({
      url: '/admin/create-device',
      method: 'POST',
      data: {
        os: os,
        language: language,
      },
    });
    if (!deviceData) throw new Error('Something went wrong!');
    await ConfigsStore.set('device_id', deviceData?.id);
  } catch (error: any) {
    throw new Error('Error');
  }
};
