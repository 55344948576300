import type { AuthProvider } from '@refinedev/core';
import { API_KEY, bundle_id } from './configs';
import { AccountStore, ConfigsStore } from './Store';
import axios from 'axios';
import { AxiosHeaders, DAdmin } from './types';
import { apiRequest } from './rest-data-provider/utils/APIs';

export const TOKEN_KEY = 'refine-auth';
export const axiosInstance = axios.create() as AxiosHeaders;
const setHeaders = async () => {
  try {
    const account = await AccountStore.get('account');
    if (account && account?.tokens.access_token) {
      axiosInstance.defaults.headers = {
        api_key: API_KEY,
        Authorization: `Bearer ${account?.tokens.access_token}`,
        bundle_id: bundle_id,
      };
    } else {
      axiosInstance.defaults.headers = {
        api_key: API_KEY,
        bundle_id: bundle_id,
      };
    }
  } catch (error) {
    error;
  }
};
export const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    try {
      const { data: accountData } = await apiRequest<DAdmin>({
        url: '/admin/login',
        data: { email, password },
        method: 'POST',
      });
      if (!accountData) throw new Error('Account Not Found!');
      await AccountStore.set('account', accountData);
      setHeaders();
      await ConfigsStore.set('logged_in', true);
      await ConfigsStore.set('resetPasswordEmail', '');
      if (await ConfigsStore.get('rememberMe')) {
        const cookies = {
          value: await ConfigsStore.get('logged_in'),
        };
        await ConfigsStore.set('cookieExpiration', cookies);
      }
      axiosInstance.defaults.headers = {
        // @ts-ignore
        Authorization: `Bearer ${accountData.tokens.access_token}`,
        api_key: API_KEY,
        bundle_id: bundle_id,
      };
      localStorage.setItem('auth', JSON.stringify(accountData));
      localStorage.setItem(TOKEN_KEY, accountData.name);
      localStorage.setItem('access_token', accountData.tokens.access_token);
      return {
        success: true,
        message: 'Login Successful',
        redirectTo: '/',
      };
    } catch (error) {
      return { success: false, error: new Error('Login failed') };
    }
  },
  logout: async () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('auth');
    localStorage.removeItem(TOKEN_KEY);
    if (await ConfigsStore.get('logged_in')) {
      await AccountStore.set('account', {});
      await ConfigsStore.set('logged_in', false);
      await ConfigsStore.set('rememberMe', false);
      await ConfigsStore.set('adminRole', '');
      await ConfigsStore.set('resetPasswordEmail', '');
    }
    sessionStorage.clear();
    return {
      success: true,
      redirectTo: '/login',
    };
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: '/login',
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return {
        id: 1,
        name: token,
        avatar: 'https://i.pravatar.cc/300',
      };
    }
    return null;
  },
  onError: async (error) => {
    if (error.status === 401 || error.status === 403) {
      return {
        logout: true,
        redirectTo: '/login',
        error,
      };
    }

    return {};
  },
};
