import React from 'react';
import {
  pickNotDeprecated,
  useActiveAuthProvider,
  useGetIdentity,
} from '@refinedev/core';
import { Layout as AntdLayout, Button, Space } from 'antd';
import {
  useThemedLayoutContext,
  type RefineThemedLayoutV2HeaderProps,
} from '@refinedev/antd';
import { Notifications } from './HeaderComponent/notifications';
import { CurrentUser } from './HeaderComponent/currentUser';
import { BarsOutlined } from '@ant-design/icons';

export const ThemedHeaderV2: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  isSticky,
  sticky,
}) => {
  const authProvider = useActiveAuthProvider();
  const { data: user } = useGetIdentity({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });

  const shouldRenderHeader = user && (user.name || user.avatar);
  const {
    // setSiderCollapsed,
    setMobileSiderOpen,
  } = useThemedLayoutContext();
  if (!shouldRenderHeader) {
    return null;
  }

  const headerStyles: React.CSSProperties = {
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12)',
    zIndex: 100,
  };

  if (pickNotDeprecated(sticky, isSticky)) {
    headerStyles.position = 'sticky';
    headerStyles.top = 0;
    headerStyles.zIndex = 1;
  }

  return (
    <AntdLayout.Header
      style={headerStyles}
      className="flex sm:justify-between items-center bg-white h-[10.11%] px-2 py-1 lg:px-12 lg:py-4"
    >
      <div className="flex gap-2">
        <div className="flex lg:hidden  flex-col justify-center">
          <Button
            size="large"
            onClick={() => setMobileSiderOpen(true)}
            icon={<BarsOutlined />}
          />
        </div>
        <div className="flex flex-col justify-center min-w-[100px] w-[11.11111%] h-[51px] ">
          <img src="/IdhLogo.png" alt="IDH Logo" className="w-full" />
        </div>
      </div>
      <div className="flex justify-end flex-grow sm:flex-none">
        <Space direction="horizontal" size="middle" style={{ lineHeight: 0 }}>
          <Notifications />
          <CurrentUser />
        </Space>
      </div>
    </AntdLayout.Header>
  );
};
