import { Button, Checkbox, Modal } from 'antd';
import React from 'react';
import { CloseIcon } from '../../Assets/Svgs';

interface Props {
  open: boolean;
  updateModalOpen: (value: boolean) => void;
  id?: number;
  triggerExport: (value: string) => void;
}

const ExtractUserDetailsModal: React.FC<Props> = (props: Props) => {
  const [checked, setChecked] = React.useState('points');
  const handleOk = () => {
    props.updateModalOpen(false);
    props.triggerExport(checked);
  };

  const handleCancel = () => {
    props.updateModalOpen(false);
  };
  return (
    <Modal
      open={props.open}
      closeIcon={
        <button onClick={handleCancel}>
          <CloseIcon />
        </button>
      }
      centered
      width={498}
      styles={{ content: { borderRadius: '24px' } }}
      title={
        <div className="text-neutral90 text-base font-semibold">
          Select data to be downloaded
        </div>
      }
      footer={false}
    >
      <div className="flex flex-col items-center gap-y-6 mt-6">
        <div className="flex justify-between w-full">
          <Checkbox
            className={`p-2 w-[48%] ${checked === 'points' ? 'bg-[#FF00061A]' : ''}`}
            onClick={() => setChecked('points')}
            checked={checked === 'points' ? true : false}
          >
            <span className="font-normal text-neutral90">Points History</span>
          </Checkbox>
          <Checkbox
            className={`p-2 w-[48%] ${checked === 'purchases' ? 'bg-[#FF00061A]' : ''}`}
            onClick={() => setChecked('purchases')}
            checked={checked === 'purchases' ? true : false}
          >
            <span className="font-normal text-neutral90">
              Purchases History
            </span>
          </Checkbox>
        </div>
        <Button
          size="large"
          type="primary"
          className="w-full rounded-[12px] text-white bg-elmokhtabarPrimary h-14"
          htmlType="submit"
          onClick={handleOk}
        >
          Download
        </Button>
        <Button
          className="text-[#848484] hover:cursor-pointer bg-inherit border-none shadow-none"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default ExtractUserDetailsModal;
