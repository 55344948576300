import * as React from 'react';
import { SVGProps } from 'react';
const FileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#535353"
      fillRule="evenodd"
      d="M4 4a2 2 0 0 1 2-2h4.586A2 2 0 0 1 12 2.586L15.414 6A2 2 0 0 1 16 7.414V16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M10.825 14H9.313V8.12l.024-.552.024-.528c-.08.08-.18.176-.3.288l-.372.324-.96.768-.756-.936 2.592-2.052h1.26V14Z"
    />
  </svg>
);
export default FileIcon;
