import React from 'react';
import { ThemedLayoutContextProvider } from '@refinedev/antd';
import { ThemedHeaderV2 as DefaultHeader } from './header';
import { ThemedSiderV2 as DefaultSider } from './sider';
import { Layout as AntdLayout } from 'antd';
import type { RefineThemedLayoutV2Props } from '@refinedev/antd';

export const ThemedLayoutV2: React.FC<RefineThemedLayoutV2Props> = ({
  children,
  Header,
  Sider,
  Title,
  Footer,
  OffLayoutArea,
  initialSiderCollapsed,
}) => {
  const SiderToRender = Sider ?? DefaultSider;
  const HeaderToRender = Header ?? DefaultHeader;

  return (
    <ThemedLayoutContextProvider initialSiderCollapsed={initialSiderCollapsed}>
      <AntdLayout style={{ minHeight: '100vh' }}>
        <HeaderToRender />
        <AntdLayout>
          <SiderToRender Title={Title} />
          <AntdLayout.Content className="bg-white px-2 py-3 lg:px-12 ">
            <div
              style={{
                minHeight: 360,
              }}
            >
              {children}
            </div>
            {OffLayoutArea && <OffLayoutArea />}
          </AntdLayout.Content>
          {Footer && <Footer />}
        </AntdLayout>
      </AntdLayout>
    </ThemedLayoutContextProvider>
  );
};
