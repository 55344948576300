import * as React from 'react';
import { SVGProps } from 'react';
const WalletIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <path
      fill="#F09B29"
      d="M3.2 3.2a1.6 1.6 0 0 0-1.6 1.6v.8h12.8v-.8a1.6 1.6 0 0 0-1.6-1.6H3.2Z"
    />
    <path
      fill="#F09B29"
      fillRule="evenodd"
      d="M14.4 7.2H1.6v4a1.6 1.6 0 0 0 1.6 1.6h9.6a1.6 1.6 0 0 0 1.6-1.6v-4ZM3.2 10.4a.8.8 0 0 1 .8-.8h.8a.8.8 0 0 1 0 1.6H4a.8.8 0 0 1-.8-.8Zm4-.8a.8.8 0 0 0 0 1.6H8a.8.8 0 0 0 0-1.6h-.8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default WalletIcon;
