import React from 'react';

type Props = {
  value?: string | React.ReactNode;
  percent?: boolean;
  title: string;
};

export const DashBoardCard = (props: Props) => {
  return (
    <div className="bg-cardBg p-6  flex flex-col items-center gap-x-6 w-1/2 rounded-xl">
      <div className="text-3xl font-bold leading-[3.875rem] text-primary">
        {props.percent ? '٪' : ''}
        {props.value}
      </div>
      <div className="text-xl font-semibold leading-[2rem] text-primary">
        {props.title}
      </div>
    </div>
  );
};
