import React, { useState } from 'react';
import { Text } from '../../text';
import { Button, Popover, Space } from 'antd';
import { NotificationMessage } from './notification-message';
import { BellIcon } from '../../../Assets/Svgs';
import { NotificationResponse } from '../../../types';
import { LoadingOutlined } from '@ant-design/icons';

// Import statements

export const Notifications: React.FC = () => {
  const [notifications /*, setNotifications*/] = useState<
    NotificationResponse[]
  >([
    {
      id: 1,
      account_id: null,
      title: 'Notification 1',
      body: 'This is the body of notification 1',
      is_read: false,
      is_sent: true,
      is_for_all: false,
      is_schedule: false,
      schedule_date: '2024-06-05T10:00:00Z',
      is_archived: false,
      is_deleted: false,
      created_at: '2024-06-04T08:00:00Z',
      updated_at: null,
      params: null,
      account: [
        {
          phone_number: '123-456-7890',
        },
      ],
    },
    {
      id: 2,
      account_id: null,
      title: 'Notification 2',
      body: 'This is the body of notification 2',
      is_read: true,
      is_sent: false,
      is_for_all: true,
      is_schedule: true,
      schedule_date: '2024-07-01T12:00:00Z',
      is_archived: true,
      is_deleted: false,
      created_at: 'null',
      updated_at: null,
      params: null,
      account: [
        {
          phone_number: '098-765-4321',
        },
      ],
    },
    {
      id: 3,
      account_id: null,
      title: 'Notification 3',
      body: 'This is the body of notification 3',
      is_read: false,
      is_sent: true,
      is_for_all: false,
      is_schedule: true,
      schedule_date: '2024-08-10T15:00:00Z',
      is_archived: false,
      is_deleted: true,
      created_at: 'null',
      updated_at: null,
      params: null,
      account: [
        {
          phone_number: '555-555-5555',
        },
      ],
    },
  ]);
  const [loadingContent /*, setLoadingContent*/] = useState<boolean>(false);
  //   const hasMounted = useRef(false);

  //? GetAdminNotificationsAction
  //   useEffect(() => {
  //     if (!hasMounted.current) {
  //       hasMounted.current = true;
  //       const fetchData = async () => {
  //         try {
  //           setLoadingContent(true);
  //           const data = await GetAdminNotificationsAction();
  //           setNotifications(data as NotificationResponse[]);
  //         } catch (error) {
  //           setNotifications([]); // Handle error gracefully
  //         } finally {
  //           setLoadingContent(false);
  //         }
  //       };
  //       fetchData();
  //     }

  //     return () => {};
  //   }, []);

  const renderNotifications = () => {
    if (loadingContent) {
      return <LoadingOutlined />;
    } else if (notifications.length > 0) {
      return notifications.map((message) => (
        <Space
          key={message.id}
          style={{
            // padding: '8px 0',
            width: '100%',
          }}
          className="py-2.5 px-[13px] hover:bg-grayLight"
        >
          <NotificationMessage message={message.body} />
        </Space>
      ));
    } else {
      return <div>No notifications</div>;
    }
  };

  const content = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Text className="font-semibold text-neutral70 text-sm py-1 px-3">
        Notifications
      </Text>
      {renderNotifications()}
    </Space>
  );

  return (
    <Popover
      placement="bottomRight"
      content={content}
      trigger="click"
      overlayStyle={{ width: '16.3125rem' }}
      overlayInnerStyle={{ padding: '8px 0px', gap: '8px' }}
      arrow={false}
    >
      <Button
        shape="default"
        icon={<BellIcon />}
        style={{
          border: 0,
          width: '100%',
          textAlign: 'center',
          padding: '10px 12px',
          gap: '0.5rem',
          backgroundColor: '#F8F8F8',
          height: '2.625rem',
        }}
      />
    </Popover>
  );
};
