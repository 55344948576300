import React from 'react';
import {
  useActiveAuthProvider,
  useGetIdentity,
  useLogout,
} from '@refinedev/core';
import { Button, Popover } from 'antd';
import { Text } from '../../text';

import { ArrowDown, Logout, UserIcon } from '../../../Assets/Svgs';

export const CurrentUser: React.FC = () => {
  const { mutate: logout } = useLogout();
  const authProvider = useActiveAuthProvider();
  const { data: user } = useGetIdentity({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });
  const content = (
    <Button
      icon={<Logout />}
      className="flex justify-between w-full"
      type="text"
      onClick={() => logout()}
    >
      Logout
    </Button>
  );

  return (
    <>
      <Popover
        placement="bottomRight"
        content={content}
        trigger="click"
        overlayInnerStyle={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: 0,
          height: '2.87rem',
        }}
        overlayStyle={{ width: '10.25rem' }}
        style={{ display: 'flex', alignItems: 'center' }}
        arrow={false}
      >
        <Button
          icon={<UserIcon />}
          style={{
            border: 0,
            width: '100%',
            textAlign: 'center',
            paddingTop: '10px',
            gap: '0.5rem',
            backgroundColor: '#F8F8F8',
            height: '2.625rem',
          }}
          className="flex justify-between "
          dir="rtl"
        >
          <div className="hidden sm:block">
            <Text color={'#0A0A0A'} style={{ fontWeight: '600' }} size="sm">
              {user?.name}
            </Text>
          </div>
          <ArrowDown />
        </Button>
      </Popover>
    </>
  );
};
