import * as React from 'react';
import { SVGProps } from 'react';
const StarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#F09B29"
      d="M7.24 3.142c.239-.737 1.282-.737 1.521 0l.856 2.633a.8.8 0 0 0 .76.553h2.77c.774 0 1.097.992.47 1.447l-2.24 1.628a.8.8 0 0 0-.291.894l.856 2.634c.24.737-.604 1.35-1.232.894l-2.24-1.627a.8.8 0 0 0-.94 0l-2.24 1.627c-.627.456-1.47-.157-1.231-.894l.855-2.634a.8.8 0 0 0-.29-.894l-2.24-1.628c-.627-.455-.305-1.447.47-1.447h2.769a.8.8 0 0 0 .76-.553l.856-2.633Z"
    />
  </svg>
);
export default StarIcon;
