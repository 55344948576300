import { CSSProperties } from 'react';

export const layoutStyles: CSSProperties = {};

export const containerStyles: CSSProperties = {
  // maxWidth: '',
  // margin: 'auto',
  width: '100%',
  padding: '0px',
  justifyContent: 'center',
};

export const headStyles: CSSProperties = {
  borderBottom: 0,
  padding: 0,
};

export const bodyStyles: CSSProperties = { padding: 0, marginTop: 0 };

export const titleStyles: CSSProperties = {
  textAlign: 'center',
  marginBottom: 0,
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 700,
  overflowWrap: 'break-word',
  hyphens: 'manual',
  textOverflow: 'unset',
  whiteSpace: 'pre-wrap',
};
